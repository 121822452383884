import React, { useCallback } from 'react';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import FormHeader from '../../common/components/FormHeader';
import styles from '../../common/styles/Form.module.css';
import { Button } from '../../common/components/Common.components.export';
import { preventEnterKey } from '../../../constants/preventEnterKey';

const DeliveryZoneForm = React.memo(({ data, setData, cities }) => {

    const handleSubmitDeliveryZones = useCallback((values, { resetForm }) => {
        setData(prevZones => [...prevZones, ...values.delivery_zones]);
        resetForm();
    }, [setData]);

    const handleRemoveSubmittedZone = useCallback((index) => {
        setData(prevZones => prevZones.filter((_, i) => i !== index));
    }, [setData]);

    const cityOptions = React.useMemo(() => {
        // Ensure values.delivery_zones is defined and is an array
        const selectedCities = (data || []).flatMap(zone => zone.cities.map(city => city.id));
        // Filter out selected cities from the options
        return cities
            .filter(city => !selectedCities.includes(city.id))
            .map(city => ({
                value: city.id,
                label: city.name
            }));
    }, [cities, data]);


    return (
        <FormHeader headingText='Delivery Zones' requiredText="(Required)">
            <Formik
                initialValues={{
                    delivery_zones: [{
                        name: '',
                        price_per_order: '',
                        cities: []
                    }]
                }}
                validationSchema={Yup.object({
                    delivery_zones: Yup.array().of(
                        Yup.object({
                            name: Yup.string().required('Zone Name is required.'),
                            price_per_order: Yup.number().required('Per Order Price is required.').positive('Per Order Price must be positive.'),
                            cities: Yup.array().of(
                                Yup.object({
                                    id: Yup.string().required('Required'),
                                    name: Yup.string().required('Required')
                                })
                            ).min(1, 'At least one city is required.')
                        })
                    )
                })}
                onSubmit={handleSubmitDeliveryZones}
            >
                {({ values, setFieldValue }) => (
                    <Form onKeyDown={preventEnterKey}>
                        <FieldArray name="delivery_zones">
                            {() => (
                                <>
                                    {values.delivery_zones.map((zone, index) => (
                                        <div key={index} className={styles.form_group}>
                                            <div className={styles.form_group}>
                                                <label htmlFor={`delivery_zones[${index}].name`}>Zone Name</label>
                                                <Field type="text" id={`delivery_zones[${index}].name`} name={`delivery_zones[${index}].name`} className={styles.form_control} />
                                                <ErrorMessage name={`delivery_zones[${index}].name`} component="div" className={styles.error_message} />
                                            </div>
                                            <div className={styles.form_group}>
                                                <label htmlFor={`delivery_zones[${index}].price_per_order`}>Per Order Price</label>
                                                <Field type="number" id={`delivery_zones[${index}].price_per_order`} name={`delivery_zones[${index}].price_per_order`} className={styles.form_control} />
                                                <ErrorMessage name={`delivery_zones[${index}].price_per_order`} component="div" className={styles.error_message} />
                                            </div>
                                            <div className={styles.form_group}>
                                                <label htmlFor={`delivery_zones[${index}].cities`}>Cities</label>
                                                <Select
                                                    id={`delivery_zones[${index}].cities`}
                                                    name={`delivery_zones[${index}].cities`}
                                                    isMulti
                                                    options={cityOptions}
                                                    placeholder={'Select cities'}
                                                    value={values.delivery_zones[index].cities.map(city => ({
                                                        value: city.id,
                                                        label: city.name
                                                    }))}
                                                    onChange={selectedOptions => setFieldValue(
                                                        `delivery_zones[${index}].cities`,
                                                        selectedOptions.map(option => ({
                                                            id: option.value,
                                                            name: option.label
                                                        }))
                                                    )}
                                                    className={styles.react_select_container}
                                                    classNamePrefix="react-select"
                                                />
                                                <ErrorMessage name={`delivery_zones[${index}].cities`} component="div" className={styles.error_message} />
                                            </div>
                                        </div>
                                    ))}
                                    <Button
                                        text="Save Delivery Zone"
                                        className="button_grey"
                                        type='submit'
                                    />
                                </>
                            )}
                        </FieldArray>
                    </Form>
                )}
            </Formik>

            {data.length > 0 && (
                <div className={styles.submitted_data_box}>
                    <div className={styles.submitted_data_heading}>Submitted Delivery Zones</div>
                    {data.map((zone, index) => (
                        <div key={index} className={styles.submitted_content}>
                            <div className={styles.submitted_data_text}>
                                <p><strong>Zone Name:</strong> {zone.name}</p>
                            </div>
                            <div className={styles.submitted_data_text}>
                                <p><strong>Per Order Price:</strong> {zone.price_per_order} KWD</p>
                            </div>
                            <div className={styles.submitted_data_text}>
                                <p><strong>Cities:</strong> {zone.cities.map(city => city.name).join(', ')}</p>
                            </div>
                            <Button
                                text="Remove"
                                onClick={() => handleRemoveSubmittedZone(index)}
                                className="button_red"
                            />
                        </div>
                    ))}
                </div>
            )}
        </FormHeader>
    );
});

export default DeliveryZoneForm;