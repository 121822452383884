import React, { useEffect, useState } from 'react';
import storage from '../../utils/storage';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import BusinessNavigation from '../FooterNavigation';
import { PageLayout, LoadingSpinner, Button } from '../common/components/Common.components.export';
import DeliveryCompanyDropdown from './DeliveryCompanyDropdown';
import FormHeader from '../common/components/FormHeader';
import PricingPlanDropdown from './PricingPlanDropdown';
import DisplayDeliveryZones from './DisplayDeliveryZones';
import { packageSelectionSelector } from './redux/selectors';
import { getDeliveryCompanies, getPricingPlans, updateUserPackageSelection } from './redux/actions';

const PackageSelectionOverview = () => {
    const dispatch = useDispatch();
    const { deliveryCompanies, getDeliveryCompaniesLoading, pricingPlans, getPricingPlansLoading } = useSelector(packageSelectionSelector, shallowEqual);
    const [deliveryCompanyId, setDeliveryCompanyId] = useState(null);
    const [pricingPlanId, setPricingPlanId] = useState(null);
    // const [selectedPricingPlan, setSelectedPricingPlan] = useState(null);
    let selectedPricingPlan = pricingPlans.find(plan => plan.id === pricingPlanId);

    useEffect(() => {
        dispatch(getDeliveryCompanies());
    }, [dispatch]);

    useEffect(() => {
        if (deliveryCompanyId) {
            selectedPricingPlan = null;
            setPricingPlanId(null);
            dispatch(getPricingPlans({ deliveryCompanyId }));
        }
    }, [dispatch, deliveryCompanyId]);

    const handleSelection = () => {
        const userId = storage.get('userId');
        const payload = {
            delivery_company_id: deliveryCompanyId,
            pricing_plan_id: pricingPlanId,
        };
        dispatch(updateUserPackageSelection({ userId, payload }));
    };
    

    return (
        <PageLayout
            title="Package Selection"
            activeTab="package-selection"
            NavigationComponent={BusinessNavigation}
        >
            {getDeliveryCompaniesLoading ? (
                <LoadingSpinner />
            ) : (
                <FormHeader headingText='Select Delivery Package'>
                    <DeliveryCompanyDropdown
                        deliveryCompanies={deliveryCompanies}
                        setDeliveryCompanyId={setDeliveryCompanyId}
                    />
                    {deliveryCompanyId && (
                        getPricingPlansLoading ? (
                            <LoadingSpinner />
                        ) : (
                            <PricingPlanDropdown
                                pricingPlans={pricingPlans}
                                setPricingPlanId={setPricingPlanId}
                            />
                        )
                    )}

                    {selectedPricingPlan && (
                        <>
                            <FormHeader headingText={selectedPricingPlan.name}>
                                <DisplayDeliveryZones
                                    pricingPlan={selectedPricingPlan}
                                />
                            </FormHeader>
                            <Button
                                text="Confirm Selection"
                                className="button_grey"
                                type='submit'
                                onClick={handleSelection}
                            />
                        </>
                    )}


                </FormHeader>
            )}
        </PageLayout>
    );
};

export default PackageSelectionOverview;