import React, { useState, useEffect } from 'react';
import { PageLayout, LoadingSpinner, Button } from '../../common/components/Common.components.export';
import PricePlanForm from './PricePlanForm';
import DeliveryZoneForm from './DeliveryZoneForm';
import PricingTierForm from './PricingTierForm';
import PriceUpdateForm from './PriceUpdateForm';
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import DeliveryCompanyNavigation from '../../../pages/driver/Navigation';

const CreatePricingPlanOverview = ({ createPricingPlanHandler, cities, getCitiesLoading, navigateToListPricingPlanPage }) => {
    const [submittedPricPlan, setSubmittedPricPlan] = useState(null);
    const [submittedZones, setSubmittedZones] = useState([]);
    const [submittedTiers, setSubmittedTiers] = useState([]);
    const [combinedData, setCombinedData] = useState({ data: {} });

    useEffect(() => {
        const generateCombinedData = () => ({
            ...submittedPricPlan,
            delivery_zones: submittedZones.map(zone => ({
                ...zone,
                cities: zone.cities.map(city => city.id),
                pricing_tiers: submittedTiers
            }))
        });

        setCombinedData(generateCombinedData());
    }, [submittedPricPlan, submittedZones, submittedTiers]);

    const handleSubmitCompletePricingPlan = () => createPricingPlanHandler(combinedData);

    return (
        <>
            <PageLayout
                title="Pricing Plans"
                activeTab="driver-pricing-plans"
                NavigationComponent={DeliveryCompanyNavigation}
            >
                {getCitiesLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <Button
                            text="Back"
                            icon={MdOutlineKeyboardBackspace}
                            className="button_icon"
                            onClick={() => navigateToListPricingPlanPage()}
                            type='button'
                        />
                        <PricePlanForm
                            data={submittedPricPlan}
                            setData={setSubmittedPricPlan}
                        />
                        <DeliveryZoneForm
                            data={submittedZones}
                            setData={setSubmittedZones}
                            cities={cities}
                        />
                        <PricingTierForm
                            data={submittedTiers}
                            setData={setSubmittedTiers}
                            pricingPlanData={submittedPricPlan}
                            deliveryZoneData={submittedZones}
                        />
                        {submittedPricPlan && submittedZones.length > 0 && (
                            <PriceUpdateForm
                                data={combinedData}
                                setData={setCombinedData}
                                submittedTiers={submittedTiers}
                                handleSubmitCompletePricingPlan={handleSubmitCompletePricingPlan}
                            />
                        )}
                    </>
                )}
            </PageLayout>
        </>
    );
};

export default CreatePricingPlanOverview;