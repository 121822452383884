import React, { memo, useCallback } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import FormHeader from '../../common/components/FormHeader';
import styles from '../../common/styles/Form.module.css';
import { preventEnterKey } from '../../../constants/preventEnterKey';
import { Button } from '../../common/components/Common.components.export';

const PricePlanForm = memo(({ data, setData }) => {
    const handleSubmitPricingPlan = useCallback((values, { resetForm }) => {
        setData(values);
        resetForm();
    }, [setData]);

    return (
        <>
            <FormHeader headingText="Price Plan" requiredText="(Required)">
                <Formik
                    initialValues={{ name: '', period: 'monthly' }}
                    validationSchema={Yup.object({
                        name: Yup.string().required('Name is required.'),
                        period: Yup.string().required('Period is required.'),
                    })}
                    onSubmit={handleSubmitPricingPlan}
                >
                    <Form onKeyDown={preventEnterKey}>
                        <div className={styles.form_group}>
                            <label htmlFor="name">Name</label>
                            <Field type="text" id="name" name="name" className={styles.form_control} />
                            <ErrorMessage name="name" component="div" className={styles.error_message} />
                        </div>
                        <div className={styles.form_group}>
                            <label htmlFor="period">Period</label>
                            <Field as="select" id="period" name="period" className={styles.form_control}>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                            </Field>
                            <ErrorMessage name="period" component="div" className={styles.error_message} />
                        </div>
                        <Button
                            text="Save Price Plan"
                            className="button_grey"
                            type='submit'
                        />
                    </Form>
                </Formik>
                {data && (
                    <div className={styles.submitted_data_box}>
                        <div className={styles.submitted_data_heading}>Submitted Price Plan</div>
                        <div className={styles.submitted_data_text}>
                            <p><strong>Name:</strong> {data.name}</p>
                        </div>
                        <div className={styles.submitted_data_text}>
                            <p><strong>Period:</strong> {data.period.charAt(0).toUpperCase() + data.period.slice(1)}</p>
                        </div>
                    </div>
                )}
            </FormHeader>
        </>
    );
});

export default PricePlanForm;