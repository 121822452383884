import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageLayout, LoadingSpinner, Button } from '../../common/components/Common.components.export';
import styles from './styles/PricingPlanOverview.module.css';
import DisplayPricingPlan from './DisplayPricingPlan';
import DeliveryCompanyNavigation from '../../../pages/driver/Navigation';

const CompleteListPricingPlan = ({ getPricingPlansLoading, pricingPlans, updatePricingPlanHandler, deletePricingPlanHandler }) => {
    const navigate = useNavigate();
    const navigateToCreatePricingPlanPage = () => navigate('/driver/pricingPlans/create');

    return (
        <PageLayout
            title="Pricing Plans"
            activeTab="driver-pricing-plans"
            NavigationComponent={DeliveryCompanyNavigation}
        >
            {getPricingPlansLoading ? (
                <LoadingSpinner />
            ) : (
                <div>
                    <div className={styles.navigation_button}>
                        <Button
                            text="Create Pricing Plan"
                            className="button_blue"
                            onClick={navigateToCreatePricingPlanPage}
                        />
                    </div>
                    <DisplayPricingPlan
                        pricingPlans={pricingPlans}
                        updatePricingPlanHandler={updatePricingPlanHandler}
                        deletePricingPlanHandler={deletePricingPlanHandler}
                    />
                </div>
            )}
        </PageLayout>
    );
};

export default CompleteListPricingPlan;