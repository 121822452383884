import { useState, useRef , useEffect } from 'react';
import BusinessNavigation from '../FooterNavigation';
import { PageLayout, FormBackground, OrderButton } from './common/components/orderTracking.common.components';
import styles from './styles/OrderTrackingDashboardOverview.module.css';
import OrderTable from './OrderTable';


const OrderTrackingDashboardOverview = () => {

  const [buttonClicked, setButtonClicked] = useState('AllOrders');
  const [orderSource, setOrderSource] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Reference for the table container
  const tableContainerRef = useRef(null);

  const handleAllOrdersSelection = () => {
    setButtonClicked('AllOrders');
    setOrderSource('');
    setCurrentPage(1);
  }

  const handleShopifyOrdersSelection = () => {
    setButtonClicked('ShopifyOrders');
    setOrderSource('SHOPIFY');
    setCurrentPage(1);
  }

  const handleManualOrdersSelection = () => {
    setButtonClicked('ManualOrders');
    setOrderSource("ONWAY");
    setCurrentPage(1);
  }

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;  // Reset vertical scroll
      tableContainerRef.current.scrollLeft = 0; // Reset horizontal scroll
    }
  }, [orderSource, currentPage]);

  return (
    <PageLayout
      title="Orders Tracking"
      activeTab="dashboard"
      NavigationComponent={BusinessNavigation}
    >
      <>
        <FormBackground>
          <div className={styles.top_button_layer}>
            <OrderButton
              text="All Orders"
              className={buttonClicked === 'AllOrders' ? 'button_blue' : 'button_grey'}
              type='button'
              onClick={handleAllOrdersSelection}
            />
            <OrderButton
              text="Shopify Orders"
              className={buttonClicked === 'ShopifyOrders' ? 'button_blue' : 'button_grey'}
              type='button'
              onClick={handleShopifyOrdersSelection}
            />
            <OrderButton
              text="Manual Orders"
              className={buttonClicked === 'ManualOrders' ? 'button_blue' : 'button_grey'}
              type='button'
              onClick={handleManualOrdersSelection}
            />
          </div>
          <div className={styles.heading_form_divider}></div>
          <>
            <FormBackground>
              <OrderTable
                key={`${orderSource}-${currentPage}`}
                orderSource={orderSource}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </FormBackground>
          </>
        </FormBackground>
      </>


    </PageLayout>
  );
};

export default OrderTrackingDashboardOverview;