import { DELIVERY_STATUS_TITLE, DELIVERY_STATUS_COLOR } from '../../common/constants'

export default function DeliveryStatusFormat({ status }) {
	return (
		<span
			style={{
				backgroundColor: DELIVERY_STATUS_COLOR[status],
				color: '#fff', // Optional: Set text color for better contrast
				padding: '5px 10px', // Optional: Add padding for a better look
				borderRadius: '5px', // Optional: Make the background look like a pill or badge
				display: 'inline-block' // Optional: Ensure it looks like a block element
			}}
		>
			{DELIVERY_STATUS_TITLE[status]}
		</span>
	)
}