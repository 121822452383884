import React, { useState } from 'react';
import styles from './styles/PricingPlanHeader.module.css';
import { Button, ConfirmationModal } from '../../common/components/Common.components.export';

const PricingPlanHeader = ({ pricingPlan, updatePricingPlanHandler, deletePricingPlanHandler }) => {
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
    const confirmAction = () => setIsConfirmationModalVisible(true);
    const handleConfirmation = () => {
        setIsConfirmationModalVisible(false);
        deletePricingPlanHandler(pricingPlan.id);
    };
    const handleCancel = () => setIsConfirmationModalVisible(false);

    return (
        <div className={styles.pricing_plan_header}>
            <div className={styles.pricing_plan_name}>{pricingPlan.name}</div>
            <div className={styles.pricing_plan_actions}>
                <div className={styles.pricing_plan_toggle}>
                    <label className={styles.switch}>
                        <input
                            type="checkbox"
                            checked={pricingPlan.is_active}
                            onChange={() => {
                                const payload = { is_active: !pricingPlan.is_active };
                                updatePricingPlanHandler(pricingPlan.id, payload);
                            }}
                        />
                        <span className={`${styles.slider} ${styles.slider_round}`}></span>
                    </label>
                    <span className={styles.toggle_text}>
                        {pricingPlan.is_active ? 'Active' : 'Inactive'}
                    </span>
                </div>
                <Button
                    text="Delete"
                    className="button_red"
                    onClick={confirmAction}
                />
            </div>
            <ConfirmationModal
                isVisible={isConfirmationModalVisible}
                title={pricingPlan.name}
                message="Are you sure you want to delete this pricing plan?"
                onConfirm={handleConfirmation}
                onCancel={handleCancel}
            />
        </div>
    );
};

export default PricingPlanHeader;